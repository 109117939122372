import { createContext } from "react";
import { UserContextType } from "../types";

export const defaultUserData: UserContextType = {
  id: 0, username: undefined, first_name: undefined,
  invite_code: '', balance: 0, referrals_left: 0, allows_pm: true,
  has_wallet: true, wallet: null, pending: 0, boosts: [],
  things: [], onboarding: { task_id: 2, status: false }, nfts: [],
  rituals: { claimed: 0, thr: 1 }
}
export const UserContext = createContext(defaultUserData);
