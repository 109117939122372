
import { Howl } from 'howler';

export interface Message {
  method: string,
  error: boolean,
  data: object
}

export interface ReferralType {
  username: string | JSX.Element | undefined,
  balance: number,
  date_joined: string,
  referrals_count: number,
  inactive_from: string | null
}

export interface UserBoost {
  kind: string,
  value: number,
  using: boolean,
  expiration: string | undefined
}

interface OnboardingType {
  task_id: number | null,
  status: boolean
}

export interface UserRitualsCountType {
  claimed: number,
  thr: number,
}

export interface UserContextType {
  id: number,
  username: string | undefined,
  first_name: string | undefined,
  invite_code: string,
  referrals_left: number,
  allows_pm: boolean,
  balance: number,
  wallet: string | null,
  has_wallet: boolean,
  pending: number,
  boosts: UserBoost[],
  things: string[],
  onboarding: OnboardingType,
  nfts: number[]
  rituals: UserRitualsCountType;
}

export interface StreamType {
  [key: string]: boolean | string | (() => void) | ((sound: string) => void) | Howl | undefined,
  mute: boolean | undefined,
  startStopSound: (() => void) | undefined
  sound: string | undefined
  setSound: ((sound: string) => void) | undefined
  player: Howl | undefined
}

export interface ControlContextType {
  music: StreamType,
  game: GameModeType,
  setScreenIdx: (idx: number) => void
}

export interface TaskData {
  id: number
  description: string,
  reward: number,
  link: string,
  completed: boolean,
  claimed: boolean,
  expiration: string | null,
  icon_name: string,
  is_partner: boolean | null,
  tracked: boolean,
  special: string | null
}

export interface TaskContextType {
  tasks_available: number,
}

export interface GameModeType {
  mode: number,
  version: number
}

interface RelicArgs {
  value: number
  asset: string | null
  decimals: number | null
}

export interface RelicData {
  type: string
  cost: number
  args: RelicArgs
  sprite: string
}

export const enum BoostKind {
  Pray = 'boost-pray',
  Faith = 'boost-faith',
}
